<template>
  <div class="row mt-5 pt-2">
    <section class="no-service col-md-6 col-12">
      <h2 class="fs-b mb-3">Service non accessible</h2>
      <div
        v-if="
          rattachement_net_e &&
          !rattachement_dgfip &&
          !rattachement_dgddi &&
          !rattachement_urssaf
        "
      >
        <p class="mb-4">
          Afin de bénéficier des fonctionnalités, nous vous suggérons de
          rattacher un compte impots.gouv.fr, urssaf.fr ou douane.gouv.fr.
        </p>
        <p class="mb-4">
          Pour plus d’informations sur le fonctionnement de net-entreprises,
          veuillez consulter la
          <router-link
            :to="{
              name: 'Foire aux questions',
              query: {
                q:
                  'que-faire-sur-portailpro-avec-un-rattachement-net-entreprises'
              }
            }"
          >
            FAQ
          </router-link>
          .
        </p>
        <p class="mb-3">
          <router-link
            class="fr-btn fr-btn--lg mr-2 btn-rattacher mb-2"
            :to="{ name: 'Gestion des rattachements' }"
            title="Rattacher un compte"
            >Rattacher un compte</router-link
          >
        </p>
      </div>
      <div v-else>
        <p class="mb-4">
          Vous ne disposez pas des habilitations nécessaires pour accéder aux
          fonctionnalités de ce portail.
        </p>
        <p class="mb-3">
          <router-link
            class="fr-btn fr-btn--lg fr-btn--secondary mr-2 btn-rattacher mb-2"
            :to="{ name: 'Gestion des rattachements' }"
            title="Rattacher un compte"
            >Rattacher un compte</router-link
          >
          <br v-if="windowWidth < 760" />
          <router-link
            class="fr-btn fr-btn--lg btn-habiliter mt-2"
            :to="{ name: 'Gestion des habilitations' }"
            title="Demander des habilitations"
            >Demander des habilitations</router-link
          >
        </p>
      </div>
    </section>
    <section class="col-md-6 col-12">
      <img
        class="rs-w-260"
        src="../assets/img/non-accessible.svg"
        alt="image d'un ordinateur avec sur l'écran un panneau sens interdit"
      />
    </section>
  </div>
</template>

<script>
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { mapGetters } from 'vuex';

export default {
  mixins: [SkipLinkMixin],
  data() {
    return {
      windowWidth: 0,
      proconnectFaqURL: '/ms/foire-aux-questions',
      rattachement_dgfip: false,
      rattachement_dgddi: false,
      rattachement_urssaf: false,
      rattachement_net_e: false
    };
  },
  computed: {
    ...mapGetters(['comptesRattachees'])
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.loadRattachement();
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    loadRattachement() {
      this.rattachement_dgfip = this.comptesRattachees?.dgfip ? true : false;
      this.rattachement_dgddi = this.comptesRattachees?.dgddi ? true : false;
      this.rattachement_urssaf = this.comptesRattachees?.urssaf ? true : false;
      this.rattachement_net_e = this.comptesRattachees?.net_e ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-rattacher {
  min-width: 214px;
  font-size: 1rem;
  padding: 12px 24px;
  height: 40px;
  min-height: unset;
  font-weight: inherit;
}
.btn-habiliter {
  min-width: 263px;
  font-size: 1rem;
  padding: 12px 24px;
  height: 40px;
  min-height: unset;
  font-weight: inherit;
}

@media only screen and (max-width: 760px) {
  .no-service {
    order: 2;
  }
  .bg-non-accessible {
    order: 1;
  }
  a.btn-rattacher {
    margin-bottom: 3%;
  }
  a.btn-habiliter {
    margin-bottom: 3%;
  }
  p.mb-3 {
    display: grid;
    justify-items: center;
  }
  h2.mb-3 {
    font-size: 34px;
    text-align: center;
  }
  .rs-w-260 {
    max-width: 260px;
  }
}
</style>
